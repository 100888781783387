import { useEffect, useState, useContext } from "react";
import styles from "./popup-register.module.css";
import Image from "next/image";
import ButtonUi from "../../ui/button-ui";
import RegisterForm from "../signup-page/register-form";
import UserContext from "../../stores/user-context";
import { TfiClose } from "react-icons/tfi";

const PopupRegister = () => {
  const [ShowRegister, setShowRegister] = useState(false);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const popupCookie = document.cookie.includes("RegisterPopup=false");
      if (!popupCookie) {
        setShowRegister(true);
      }
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  const hideAccountPopup = () => {
    document.cookie = "RegisterPopup=false; Max-Age=" + 60 * 60 * 24 * 7;
    setShowRegister(false);
  };

  if (!userCtx.user) {
    return (
      <>
        {ShowRegister && (
          <div className={styles.overlay}>
            <div className={styles.popup}>
              <div className={styles.form_register_popup}>
                <div className={styles.title}>Become Aurum Lab Member Now!</div>
                <div className={styles.desc}>
                  Join our membership, get points, and use it on your next
                  purchase!
                </div>
                <RegisterForm
                  register_text={"Register & Get 50K Voucher"}
                  closePopup={{ hideAccountPopup }}
                />
                <div
                  className={styles.button_register}
                  onClick={hideAccountPopup}
                >
                  <ButtonUi button_class="button_grey">
                    Remind Me Later
                  </ButtonUi>
                </div>
              </div>
              <div className={styles.bg_img}>
                <Image
                  src="https://res.cloudinary.com/aurumlab/image/upload/v1714700023/aurumlab/banner/homepage/Regiter_Popup_e8gu1p.jpg"
                  fill
                  sizes="true"
                  loading="lazy"
                  alt="popup image"
                />
                <div
                  className={styles.close_icon_popup}
                  onClick={hideAccountPopup}
                >
                  <TfiClose />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default PopupRegister;
